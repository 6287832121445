import React, { useState } from 'react';
import './CartQuickView.scss';
import CartIcon from '../../../assets/imgs/shopping-cart.png'

export interface CartItem {
    id: number;
    image: string;
    title: string;
    description: string;
    price: number;
    quantity: number;
}

interface CartQuickViewProps {
    items: CartItem[];
    total: number;
    quantities: number;
    onRemove: (index: number) => void;
    onCheckout: () => void;
}

const CartQuickView: React.FC<CartQuickViewProps> = ({ items, total, quantities, onRemove, onCheckout }) => {
    const [showCart, setShowCart] = useState(false)
    return (
        <div className={"cart-quick-view "+(showCart?"":"cart-quick-view-hide")}>
            {!showCart && <div className="cart-summary cart-summary-hide">
                <div className='cart-title-hide' onClick={() => setShowCart(true)}>
                    <img src={CartIcon} />
                    <div className='cart-title-hide-total'>
                        <strong>${total}.00</strong>
                        <span>{quantities} producto{quantities!=1?"s":""}</span>
                    </div>
                </div>
            </div>
            }
            {showCart && <div className="cart-summary">
                    <h3>Total <span onClick={() => setShowCart(false)}>X</span></h3>
                    <div className="cart-body">
                        <ul className="cart-items">
                            {items.map((item, index) => (
                                <li key={index} className="cart-item">
                                    <img src={item.image} alt={item.title} className="cart-item-image" />
                                    <div className="cart-item-details">
                                        <h4>({item.quantity}) {item.title}</h4>
                                        <p>{item.description}</p>
                                        <div className="cart-item-price">
                                            <span>Precio: <strong>${item.price}</strong></span>
                                            <span>Total: <strong>${item.price * item.quantity}</strong></span>
                                        </div>
                                        <button onClick={() => onRemove(index)} className="cart-item-remove">🗑 Eliminar</button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="cart-total">
                            <p>Total: ${total}</p>
                        </div>
                        <button onClick={onCheckout} className="checkout-button">CHECKOUT</button>
                    </div>

                </div>
            }
        </div>
    );
};

export default CartQuickView;
