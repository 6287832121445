// src/components/Sidebar.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

interface SidebarProps {
    isOpen: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
    return (
        <div className={`sidebar ${isOpen ? 'sidebar--open' : ''}`}>
            <button className="sidebar__close" onClick={toggleSidebar}>X</button>
            <nav className="sidebar__nav">
                <ul>
                    <li><Link to="/" onClick={toggleSidebar}>Home</Link></li>
                    <li><Link to="/about" onClick={toggleSidebar}>About</Link></li>
                    <li><Link to="/services" onClick={toggleSidebar}>Services</Link></li>
                    <li><Link to="/contact" onClick={toggleSidebar}>Contact</Link></li>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
