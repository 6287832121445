// src/components/Header.tsx

import React, { useState } from 'react';
import { FaBars, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import './Header.css';

const Header: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <header className="header">
                <div className="header__left">
                    <FaBars className="header__menu-icon" onClick={toggleSidebar} />
                </div>
                <div className="header__center">
                    <h1 className="header__title">PPIQZON</h1>
                </div>
                <div className="header__right">
                    <div className='header-auth'>
                        <div className='header-auth__left'>
                            <FaUser className="header__menu-icon"/>
                        </div>
                        <div className='header-auth__right'>
                            <Link to="/login">Iniciar Sesión</Link>
                            <Link to="/register">Registrarse</Link>
                        </div>
                    </div>
                </div>
            </header>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </>
    );
};

export default Header;
