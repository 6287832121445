import React from 'react';

export interface IMenuItemProps {
    id: number;
    image: string;
    title: string;
    description: string;
    price: number;
    clicked: (item: IMenuItemProps)=>void;
    quantity: number;
}

const MenuItem: React.FC<IMenuItemProps> = (item: IMenuItemProps) => {
    return (
        <div className="menu-item">
            <div className="menu-item-image">
                <img src={item.image} alt={item.title} />
            </div>
            <div className="menu-item-content">
                <h3 className="menu-item-title">{item.title}</h3>
                <p className="menu-item-description">{item.description}</p>
                <p className="menu-item-price">${item.price}</p>
                <p className="menu-item-tax">*impuestos incluidos</p>
                <button onClick={()=>{item.clicked(item)}} className="menu-item-button">AÑADIR RÁPIDO</button>
            </div>
        </div>
    );
};

export default MenuItem;
