import React, { useEffect, useState } from 'react';
import PizzaCarousel from '../components/body/Carousel/Carousel'; // Asegúrate de ajustar la ruta según sea necesario
import '../assets/styles/scss/Home.scss';
import Menu, { IMenu } from '../components/body/Menu/Menu'
import CartQuick, {CartItem} from '../components/footer/CartQuickView/CartQuickView'
import {IMenuItemProps} from '../components/body/Menu/MenuItem';


const Home: React.FC = () => {
    const [cartItems, setCartItems] = useState<CartItem[]>(()=>{
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const handleRemove = (index: number) => {
        const newItems = cartItems.filter((_, i) => i !== index);
        setCartItems(newItems);
    };

    const handleCheckout = () => {
        alert('Proceeding to checkout');
    };

    const addItems = (list: IMenuItemProps[]) => {
        const items = list.map(item => {
            const it: CartItem = {
                id: item.id,
                description: item.description,
                image: item.image,
                price: item.price,
                quantity: item.quantity,
                title: item.title
            }
            return it
        })
        setCartItems(items)
    }

    const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const quantities = cartItems.reduce((acc, item) => acc + item.quantity, 0)

    return (
        <div className='principal-home'>
            <PizzaCarousel />
            <div className="announsment">
                ¡Elige tu pizza favorita y agrega todos tus antojos al carrito! Disfruta de una experiencia deliciosa en cada bocado.
            </div>
            <Menu selectedItems={addItems}/>
            <CartQuick
                items={cartItems}
                quantities={quantities}
                total={total}
                onRemove={handleRemove}
                onCheckout={handleCheckout}
            />
        </div>
    );
};

export default Home;
