import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css'
import pizzaIcon from '../../../assets/imgs/pizza.png';
const PizzaCarousel = () => {
    const slides = [
        {
            src: 'https://images.unsplash.com/photo-1534308983496-4fabb1a015ee?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            title: 'Pizza Margarita',
            description: 'Deliciosa Pizza Margarita'
        },
        {
            src: 'https://images.unsplash.com/photo-1528137871618-79d2761e3fd5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            title: 'Pizza Pepperoni',
            description: 'Exquisita Pizza Pepperoni'
        },
        {
            src: 'https://media.istockphoto.com/id/1218745597/es/foto/pizza-con-pepperoni-picado-en-trozos-en-una-caja-de-cortes%C3%ADa-enfoque-selectivo-vista-desde.jpg?s=1024x1024&w=is&k=20&c=_hlG39BjMBQ3O0YAMzioH88bKdQwPo96Bxasj05wY-s=',
            title: 'Promoción 2x1',
            description: '¡Aprovecha nuestra promoción 2x1!'
        }
        // Agrega más imágenes según necesites
    ];

    return (
        <Carousel
            showThumbs={false}
            autoPlay
            infiniteLoop
            showStatus={false}
            interval={5000}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-left">
                        <img src={pizzaIcon} alt="Prev" />
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-right">
                        <img src={pizzaIcon} alt="Next" />
                    </button>
                )
            }
        >
            {slides.map((slide, index) => (
                <div key={index} className="carousel-slide">
                    <div className="carousel-image">
                        <img src={slide.src} alt={slide.title} />
                    </div>
                    <div className="carousel-content">
                        <h2 className="carousel-title">{slide.title}</h2>
                        <p className="carousel-description">{slide.description}</p>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default PizzaCarousel;
