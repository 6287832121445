import React, { useState } from 'react';
import MenuItem, {IMenuItemProps} from './MenuItem';
import './Menu.scss';

export interface IMenu {
    selectedItems: (list: IMenuItemProps[])=>void;
}

const Menu = (model: IMenu) => {
    const [selectedItems, setSelectedItems] = useState<IMenuItemProps[]>(()=>{
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    })
    const items = [
        {
            id: 1,
            image: 'https://itemsposistnet.s3.ap-south-1.amazonaws.com/png_itemimage/6436e03f361baca2209dc283/643711301193edd20b1bfe6f.png',
            title: 'PIZZA DE QUESO',
            description: 'Deliciosa pizza grande (14") de Queso mozzarella y salsa de jitomate (8 rebanadas)',
            price: 89.00,
            quantity: 0
        },
        {
            id: 2,
            image: 'https://itemsposistnet.s3.ap-south-1.amazonaws.com/png_itemimage/6436e03f361baca2209dc283/643711301193edd20b1bfe71.png',
            title: 'PIZZA KETO DE QUESO',
            description: 'Pizza (12") de masa a base de linaza y almendra, de Queso mozzarella y salsa de jitomate (8 rebanadas)',
            price: 120.00,
            quantity: 0
        }
    ];

    const selectedItem = (it: IMenuItemProps) => {
        let newItems: IMenuItemProps[]
        const item = {...it}
        if(!selectedItems.find(r => r.id == item.id)){
            item.quantity = 1
            newItems=[...selectedItems, item]
            setSelectedItems(newItems)
        } else {
            newItems = [...selectedItems]
            newItems.find(r => r.id == item.id)!.quantity+=1
            setSelectedItems(newItems)
        }
        model.selectedItems([...newItems])
    }

    return (
        <div className='principal-menu'>
            <div className="header-menu">

            </div>
            <div className="menu">
                {items.map((item, index) => (
                    <MenuItem
                        clicked={selectedItem}
                        id={item.id}
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        price={item.price}
                        quantity={item.quantity}
                    />
                ))}
            </div>
        </div>
    );
};

export default Menu;
